import blog from "./file/pj_blog.gif";
import calendar from "./file/calendar.gif";
import guestbook from "./file/pj_guestbook.gif";
import language from "./file/pj_main.gif";
import login from "./file/login.gif";
import Back from "./Back";

function Project () {
    return(
        <div className='resume works resume-experience'>
            <Back />
            <div className="title">Sumin In Canada</div>
            <div className="project-summary">
                <div className="content first">
                    {/* 11시간의 시차를 극복하고 언제 어디서나 소통가능한 개인 사이트를 만들고 싶은 마음에 시작한 프로젝트입니다. */}
                    11시간의 시차를 극복하고 언제 어디서나 소통가능한 개인 사이트
                </div>
                <div className="content">
                    {/* 메인 기능은 블로그 포스팅 기능이고, 그 외에 메인 페이지 화면 구성 작업에도 집중하였습니다. */}
                    블로그 포스팅을 메인 기능으로 하되, 메인 페이지 화면 구성에도 집중하였습니다. 관리자에게 승인을 받은 사람들만의 비밀스러운 사이트를 컨셉으로 해킹을 하는 듯한 느낌의 대시보드를 구현했으며, 최대한 라이브러리를 쓰지 않고 작업하였습니다. 
                {/* </div>
                <div className="content"> */}
                    {/* 관리자가 가입 승인을 해야만 접속할 수 있는 사이트라는 비밀스러운 느낌의 컨셉을 잡아, 해킹을 하는 듯한 느낌의 대시보드입니다. */}
                     
                {/* </div>
                <div className="content"> */}
                    {/* 기능들은 최대한 라이브러리를 쓰지 않고 구현해보았습니다. */}
                </div>
                {/* This website facilitates communication with my family and friends in Korea, overcoming the 11-hour time difference.<br />
                It allows us to check on each other's well-being at any time.<br />
                To enhance privacy, I designed it with a hacker's monitor theme. */}
            </div>
            <div className="summary">
                <div className="summary-title">
                    Skills
                </div>
                <div className='summary-content'>
                    <span className="dot">•</span>
                    <span className="content">Javascript, React, Redux</span>
                </div>
                <div className='summary-content'>
                    <span className="dot">•</span>
                    <span className="content">NodeJS, Express, Mysql</span>
                </div>
            </div>
            <div className="summary">
                <div className="summary-title">
                    deployment
                </div>
                <div className='summary-content'>
                    <span className="dot">•</span>
                    <span className="content">FrontEnd : GitHub Pages, Route53</span>
                </div>
                <div className='summary-content'>
                    <span className="dot">•</span>
                    <span className="content">Back-End : AWS EC2 인스턴스, Route53 </span>
                </div>
            </div>
            {/* <div className="summary"> */}
                {/* <div className="summary-title">
                    주요 기능
                </div> */}
                {/* <div className='summary-content'>
                    <span className="dot">•</span>
                    <span className="content">
                        대시보드 - 현재 시간, 
                        관리자 기능 - 사진 및 글 업로드
                        Exclusive Upload Access: Only the owner can upload photos and texts, ensuring privacy and control over shared content.
                    </span>
                </div>
                <div className='summary-content'>
                    <span className="dot">•</span>
                    <span className="content">
                        방명록
                        Guestbook: Guests can leave comments and messages to interact with the owner and each other, fostering a sense of community.
                    </span>
                </div>
                <div className='summary-content'>
                    <span className="dot">•</span>
                    <span className="content">Multilingual Support: Offering support for both Korean and English languages to accommodate users from different language backgrounds.</span>
                </div> */}
            {/* </div> */}
            {/* <div className="summary">
                <div className="summary-title">
                    Future Plans
                </div>
                <div className='summary-content'>
                    <span className="dot">•</span>
                    <span className="content">Implement real-time chat functionality to facilitate instant communication.</span>
                </div>
                <div className='summary-content'>
                    <span className="dot">•</span>
                    <span className="content">Improve the speed and efficiency of the photo uploading feature.</span>
                </div>
                <div className='summary-content'>
                    <span className="dot">•</span>
                    <span className="content">Develop user customization options, allowing users to personalize their part with custom messages, names, and colors.</span>
                </div>
                <div className='summary-content'>
                    <span className="dot">•</span>
                    <span className="content">Expand the scope of the website to transform it into a platform where users can create their own websites.</span>
                </div>
            </div> */}
            <div className="detail first">
                <div className="summary-title">
                    detail
                </div>
                <div className='summary-content'>
                    <span className="dot">•</span>
                    <span className="content">로그인 페이지</span>
                </div>
                <img className="project_img" src={login} />
                <div className="explanation">
                    <div className="explanation-content">
                        <span className="dot">-</span>
                        <span className="content">macOs의 터미널 컨셉의 로그인 화면 구현</span>
                    </div>
                    {/* Designed to resemble macOS's terminal for a private and secretive atmosphere. */}
                </div>
            </div>
            <div className="detail">
                <div className='summary-content'>
                    <span className="dot">•</span>
                    <span className="content">메인 페이지 대시보드</span>
                </div>
                <img className="project_img" src={language} />
                <div className="explanation">
                    <div className="explanation-content">
                        <span className="dot">-</span>
                        <span className="content">
                            영어지원기능, d-Day, 실시간 초시계, 방문자 수 그래프, <br />
                            깃허브 잔디 디자인의 캘린더, 사용자 목록, 방명록 기능을 구현
                        </span>
                    </div>
                    {/* Features language support, real-time clock functionality, and a count-up from the first day. */}
                </div>
            </div>
            <div className="detail">
                <div className='summary-content'>
                    <span className="dot">•</span>
                    <span className="content">방명록</span>
                </div>
                <img className="project_img" src={guestbook} />
                <div className="explanation">
                    <div className="explanation-content">
                        <span className="dot">-</span>
                        <span className="content">방명록에 답글기능을 무제한 depth로 작성할 수 있도록 구현</span>
                    </div>
                    {/* Allows users to communicate with each other with unlimited depth. */}
                </div>
            </div>
            <div className="detail">
                <div className='summary-content'>
                    <span className="dot">•</span>
                    <span className="content">Contribution 캘린더</span>
                </div>
                <img className="project_img" src={calendar} />
                <div className="explanation">
                    <div className="explanation-content">
                        <span className="dot">-</span>
                        <span className="content">
                            깃허브의 잔디를 그대로 구현
                        </span>
                    </div>
                    <div className="explanation-content">
                        <span className="dot">-</span>
                        <span className="content">
                            방문자 수와 연결해두어, 방문자 수가 높을 수록 색이 진해짐
                        </span>
                    </div>
                    {/* Styled after GitHub's contribution calendar, showing the host's login activity per day.<br />
                    Future plans to convey other contributions on the site, such as posting activity and other user login activities. */}
                </div>
            </div>
            <div className="detail last">
                <div className='summary-content'>
                    <span className="dot">•</span>
                    <span className="content">블로그 기능</span>
                </div>
                <img className="project_img" src={blog} />
                <div className="explanation">
                    <div className="explanation-content">
                        <span className="dot">-</span>
                        <span className="content">
                            {/* 관리자만 사진과 글을 등록할 수 있도록 구현 */}
                            꼭 필요한 기능만을 직접 구현하였으며 관리자만 사진과 글을 등록할 수 있음
                        </span>
                    </div>
                    {/* <div className="explanation-content">
                        <span className="dot">-</span>
                        <span className="content"> */}
                            {/* 라이브러리를 쓰지 않고 최대한 빨리 기능구현하는 데에 우선순위를 두고 작업하여 기능 개선이 필요함 */}
                            
                        {/* </span>
                    </div> */}
                    {/* A platform for sharing personal experiences or writing letters to users, with the ability for users to leave comments. */}
                </div>
            </div>
            <div className="summary">
                <div className="summary-title">
                    기능별 코드 링크
                </div>
                <div className='summary-content'>
                    <span className="dot">•</span>
                    <a className="content" href="https://github.com/Sumin1030/SuminInCanadaFE/blob/1b6bae23aca09f1cabc1f9ac8ef655d5a3834163/src/view/Login.js">로그인 페이지</a>
                </div>
                <div className='summary-content'>
                    <span className="dot">•</span>
                    <a className="content" href="https://github.com/Sumin1030/SuminInCanadaFE/blob/1b6bae23aca09f1cabc1f9ac8ef655d5a3834163/src/component/Calendar.js">깃헙 잔디</a>
                </div>
                <div className='summary-content'>
                    <span className="dot">•</span>
                    <a className="content" href="https://github.com/Sumin1030/SuminInCanadaFE/blob/1b6bae23aca09f1cabc1f9ac8ef655d5a3834163/src/view/GuestBook.js">방명록</a>
                </div>
                <div className='summary-content'>
                    <span className="dot">•</span>
                    <a className="content" href="https://github.com/Sumin1030/SuminInCanadaFE/blob/1b6bae23aca09f1cabc1f9ac8ef655d5a3834163/src/util/LanguageUtil.js">언어지원</a>
                </div>
            </div>
            <div className="summary">
                <div className="summary-title">
                    느낀점
                </div>
                <div className='summary-content'>
                    <div className='summary-content'>
                        <span className="dot">•</span>
                        <span className="content">기획부터 배포까지 혼자 작업하여 다양한 시행착오를 겪으며 성장할 수 있었던 프로젝트. 실제 사이트를 배포하여 친구, 가족들과 소통하는 것이 재미있었음.</span>
                    </div>
                </div>
                <div className='summary-content'>
                    <span className="dot">•</span>
                    <span className="content">
                        이번 프로젝트에서는 떠오르는 것들을 모두 구현해보는 데에 의의를 두었다면 <br />
                        다음 프로젝트는 <span className="bold">성능 개선</span>을 중심으로 많은 사람들이 실제로 사용할 수 있는 서비스 개발, 특히 빠른 데이터 처리나 용량이 큰 데이터를 다루는 등 <span className="bold">백엔드 파트</span>에서의 과제가 있는 프로젝트에 도전하고 싶음.
                    </span>
                </div>
            </div>
            {/* <div className="project-link" >
                <Link to='/' target="_blank">Link to this Project!</Link>
            </div> */}
        </div>
    );
}

export default Project;